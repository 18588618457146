
export default {
  name: "item-seccion-editar-permiso",
  props: {
    mostrar: { type: Boolean, required: true },
    permisosPerfil: { type: Array, required: true },
    permisosOpciones: { type: Array, required: true },
  },
  data() {
    return {
      permisosEditados: [],
      seccionOpciones: [],
      secciones: [],
    };
  },
  mounted() {
    this.armarArrayPermisos();
  },
  computed: {
    todosSeleccionados() {
      let permisos = this.permisosEditados.map((p) => p.permisos).flat();
      let permisosSeleccionados = this.permisosEditados
        .map((p) => p.permisosSeleccionados)
        .flat();
      return permisos.length == permisosSeleccionados.length;
    },
  },
  methods: {
    cancelar() {
      this.$emit("cancelar");
    },
    guardar() {
      this.$emit("guardar", this.permisosEditados);
    },
    armarArrayPermisos() {
      let soloPermisos = this.permisosPerfil.map((p) => p.permisos).flat();

      this.permisosEditados = this._.cloneDeep(this.permisosOpciones);

      // Se arma arreglo de permisos para edicion
      this.permisosEditados = this.permisosEditados.map((pe) => {
        // Se arma array de secciones
        this.seccionOpciones.push(pe.seccion);

        // Se obtienen permisos ya relacionados al perfil
        let permisos = pe.permisos.map((p) => {
          return {
            ...p,
            seleccionado: soloPermisos.some(
              (sp) => sp.permiso_id == p.permiso_id
            ),
          };
        });
        // Se retorna objeto de edicion
        return {
          ...pe,
          seccionFiltrada: false,
          permisosSeleccionados: permisos.filter((p) => p.seleccionado == true),
          permisos: permisos,
        };
      });
    },
    seleccionarSeccion(val, index) {
      // Se actualizan permisos
      let permisos = this.permisosEditados[index].permisos.map((pe) => {
        return {
          ...pe,
          seleccionado: val,
        };
      });
      this.permisosEditados[index].permisos = permisos;
      // Se actualizan permisos seleccionados
      this.permisosEditados[index].permisosSeleccionados = val
        ? this._.cloneDeep(permisos)
        : [];
    },
    seleccionarPermiso(index, val, permisoObj) {
      // Se agrega permiso
      if (val)
        this.permisosEditados[index].permisosSeleccionados.push(permisoObj);
      // Se elimina permiso
      else {
        // Se obtiene index de permiso a remover
        let indexSeleccionado = this.permisosEditados[
          index
        ].permisosSeleccionados.findIndex(
          (ps) => ps.permiso_id == permisoObj.permiso_id
        );
        // Se remueve permiso
        this.permisosEditados[index].permisosSeleccionados.splice(
          indexSeleccionado,
          1
        );
      }
    },
    seleccionarTodos(val) {
      this.permisosEditados = this.permisosEditados.map((pe) => {
        let permisosNuevos = pe.permisos.map((p) => {
          return { ...p, seleccionado: val };
        });
        return {
          ...pe,
          permisos: permisosNuevos,
          permisosSeleccionados: val ? this._.cloneDeep(permisosNuevos) : [],
        };
      });
    },
    filtrarSeccion(seccion, val) {
      this.permisosEditados.map((pe) => {
        if (pe.seccion == seccion) pe.seccionFiltrada = !val;
      });
    },
    mostrarSeccion(permiso) {
      if (this._.isEmpty(this.secciones)) return true;
      return permiso.seccionFiltrada;
    },
  },
};
